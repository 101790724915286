

export default {
  name: "CommentModal",
  methods: {
    close() {
      this.show = false;
    },
  },
  props: {
    value: Boolean,
    comment: String,
  },
  computed: {
    show: {
      get () {
        return this.value;
      },
      set (value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
