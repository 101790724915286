import { createApp } from "vue";
import App from "./App.vue";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import { VDataTableServer } from "vuetify/labs/VDataTable";
import * as directives from "vuetify/directives";
import router from "./router";
import VueAxios from "vue-axios";
import axios from "axios";
import keycloakService from "@/services/keycloak-service";
import jwtDecode from "jwt-decode";

(async () => {
  const vuetify = createVuetify({
    components: { ...components, VDataTableServer },
    directives,
  });

  axios.interceptors.request.use(async (req) => {
    const token = await keycloakService.getToken();

    req.headers.Authorization = `Bearer ${token}`;

   //req.headers["Sec-Fetch-Mode"] = "cors"
   //req.headers["Access-Control-Allow-Origin"] = "*"
   //req.headers["Access-Control-Allow-Methods"] = "DELETE, POST, GET, PATCH, OPTIONS"
   //req.headers["Access-Control-Allow-Headers"] =  "Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization" //"*"
   //req.headers["Access-Control-Allow-Credentials"] = "true"
    return req;
  });

  await keycloakService.login(async () => {
    const token = await keycloakService.getToken();
    const decoded: any = jwtDecode(token);

    if (decoded.realm_access.roles.indexOf("ROLE_ADMIN") > -1 || decoded.realm_access.roles.indexOf("ROLE_KYC") > -1) {
      const app = createApp(App)
      app.config.globalProperties.hasRoleAdmin = decoded.realm_access.roles.indexOf("ROLE_ADMIN") > -1
      app.config.globalProperties.hasRoleKYC = decoded.realm_access.roles.indexOf("ROLE_KYC") > -1
      app
        .use(vuetify)
        .use(router)
        .use(VueAxios, axios)
        .mount("#app");
      app
    } else {
      await keycloakService.logout();
    }
  });
})();
